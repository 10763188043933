<template>
	<div class="paymentBox" :class="!isMobile ? 'pcbox' : ''">
		<div class="payBox">
			<div class="titleTxt">
				<van-image
				  width="26"
				  height="26"
				  :src="require('@/assets/images/common/gm_icon.png')"
				/>
				<span style="margin-left: 10px;">GM STORE</span>
			</div>
			<p class="discountTips">{{ $t('pay.maxDiscountTips') }}</p>
			<div class="payForm" style="height: calc(100% - 12.25rem);overflow-y:scroll;">
				<van-cell-group>
				  <van-field key="orderMoney"
				      v-model="orderMoney"
				      :label="$t('pay.goodMoney')"
					  input-align="right"
					  readonly
				    >
						<template #button>
						  <span>{{moneyUnit}}</span>
						</template>
				  </van-field>
				  <van-field key="payAllCoupon" v-if="couponList.length > 0"
				      v-model="payAllCoupon"
				      :label="$t('pay.discountCoupon')"
					  input-align="right"
					  readonly
					  class="discount"
					  @click="showCouponList = true"
				    >
						<template #button>
							<div class="flexc">
								{{ $t('pay.detail') }}<van-icon name="arrow" />
							</div>
						</template>
				  </van-field>
				  <van-field key="payAllGMcoin" v-if="allGMCoin > 0"
				      v-model="payAllGMcoin"
				      :label="$t('pay.gmCoin')"
					  input-align="right"
					  readonly
					  class="discount"
					  @click="handleOpenGMcoin"
				    >
						<template #button>
							<div class="flexc">
								{{ $t('pay.detail') }}<van-icon name="arrow" />
							</div>
					    </template>
				  </van-field>
				  <!-- 目前 845战火游戏  单独开放enjoy支付-->
				  <van-cell v-if="roleInfo.gameId == 845" :label="'선택된 ' + nowPayTypeText" @click="handlePayTypeSelect" :title="$t('pay.payType')" is-link />
				</van-cell-group>
				<p v-if="!isMultiple && allGMCoin > 0" class="discountUseTips">{{ $t('pay.payTypeTips') }}</p>
			</div>
			<div class="payResult">
				<div class="moneyBox">
					<p class="total">{{ $t('pay.total') }}：<b>{{ parseInt(orderMoney) + parseInt(payAllCoupon) + parseInt(payAllGMcoin) }}</b></p>
					<p class="discount" v-if="payAllCoupon || payAllGMcoin">{{ $t('pay.discountMoney') }}：{{ parseInt(payAllCoupon) + parseInt(payAllGMcoin) }}</p>
					<div class="payTypeTip" v-if="nowPayTypeValue == 'default'" @click="showPayType">
						<van-icon name="question-o" />
						결제수단
					</div>
				</div>
				<div class="btnBoxs">
					<div class="btnBox" @click.stop="handlePayCancel">
						{{ $t('common.cancel') }}
					</div>
					<div class="btnBox active" v-if="nowPayTypeValue == 'default'" @click.stop="handlePay">
						{{ $t('pay.paybtn') }}
					</div>
					<div style="width: 50%;" class="btnBox active" v-else @click.stop="handlePay">
						{{ 'ENJOY 지불' }}
					</div>
				</div>
			</div>
			
		</div>
		
		<!-- 代金券详情 -->
		<van-popup
		  v-model:show="showCouponList"
		  round
		  closeable
		  position="bottom"
		  :style="{ height: '80%' }"
		  :close-on-click-overlay="false"
		>
		<div class="couponBox" style="padding: 0;">
			<div class="couponTitle">{{ $t('pay.discountTitle') }}</div>
			<div class="couponList">
				<ul>
					<!-- effective 0不可用，1可用 -->
					<li v-for="(item,index) in couponList" :key="index">
						<div class="van-coupon" @click="handleCouponCheck(item)" :class="{'active':item.checked,'disabled':item.effective == 0}">
							<div class="van-coupon__content">
								<div class="van-coupon__body" style="padding-left: 1rem;padding-right: 3.125rem;">
									<p class="van-coupon__name">
									{{ $t('pay.expTime') }}：{{ parseTime(item.endTime,'{y}-{m}-{d}') }}
										<span class="redNum big">{{ $t('common.mUnit') }}{{ formatNumstr(formatCouponTypeStr(item,'money')) }}</span>
									</p>
									<p class="van-coupon__valid">
										<span v-if="item.type == 1 || item.type == 0">{{ formatCouponTypeStr(item,'desc') }}</span>
										<span v-else-if="item.type == 2 && item.gameId != 0">{{ $t('pay.onlyForthisGame') }}</span>
										<span class="redNum">{{ formatCouponTypeStr(item,'type') }}</span>
									</p>
									
									<van-checkbox class="van-coupon__corner" v-model="item.checked" :disabled="item.effective == 0 ? true : false"></van-checkbox>
				
								</div>
								
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="van-coupon-list__bottom" style="position: fixed;">
				<van-button block round plain type="primary" @click="handleCancelCoupon">{{ $t('pay.noUseDiscount') }}</van-button>
			</div>
		</div>
		</van-popup>
		
		<!-- GM币详情 -->
		<van-popup
		  v-model:show="showGMCoin"
		  round
		  closeable
		  position="bottom"
		  :style="{ height: '80%' }"
		  :close-on-click-overlay="false"
		>
		<div class="couponBox" style="padding: 0;">
			<div class="couponTitle">{{ $t('pay.gmCoinTitle') }}</div>
			<div class="gmForm">
				<van-cell-group :title="$t('pay.gmCoinTips')">
				  <van-field key="allGMCoin"
				      v-model="allGMCoin"
				      :label="$t('pay.allGMCoin')"
					  input-align="right"
					  readonly
				    >
				  </van-field>
				  <van-field key="payAllGMcoin"
				      v-model="inputAllGMcoin"
				      :label="$t('pay.useGMCoin')"
					  type="digit"
					  input-align="right"
					  error
					  :placeholder="$t('pay.gmCoinPlaceholder')"
					  @update:model-value="handleInputGMcoin"
				    >
				  </van-field>
				  <van-field key="remainGMCoin"
				      v-model="remainGMCoin"
				      :label="$t('pay.remainGMCoin')"
					  input-align="right"
					  readonly
				    >
				  </van-field>
				</van-cell-group>
				
			</div>
			<div class="van-coupon-list__bottom btnBoxs" style="padding-bottom: 1rem;position: fixed;">
				<div class="btnBox" @click.stop="goSuperMall">
					{{ $t('pay.freeGetGM') }}
				</div>
				<div class="btnBox active" @click.stop="handleConfirmGM">
					{{ $t('common.confirm') }}
				</div>
			</div>
		</div>
		</van-popup>
		
		
		<!-- ENJOY支付方式 -->
		<van-popup :style="{ height: '80%' }" v-model:show="showPayTypeSelect" round position="bottom">
		  <van-picker
			:title="$t('pay.selectPayTitle')"
		    :columns="payTypeSelectList"
		    @cancel="showPayTypeSelect = false"
		    @confirm="onConfirm"
			:confirm-button-text="$t('common.confirm')"
			:cancel-button-text="$t('common.cancel')"
		  />
		</van-popup>
		
		<van-popup
		  v-model:show="isShowPayType"
		  round
		  closeable
		  style="top:-55%;width: 90%;height: 90%;position: relative;overflow: auto;"
		  :close-on-click-overlay="false"
		  @click-close-icon="handlePayTypeCancel"
		>
		<div class="payBox payTypeBox">
			<div class="titleTxt">결제수단</div>
			<div style="position: relative;">
				<typeTable></typeTable>
			</div>
		</div>
		</van-popup>
		
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,onBeforeMount,ref,watch,computed, reactive } from 'vue'
	import useUserStore from '@/store/modules/user'
	import useConfigStore from '@/store/modules/config'
	import typeTable from '@/view/game/typeTable.vue'
	import { orderPay, getUserCoupon, getUserInfo,getHgPayTypeInfo,orderEnjoy } from "@/api/payment";
	
	const { proxy } = getCurrentInstance();
	const moneyUnit = ref('₩')
	const orderMoney = ref(0)
	const allGMCoin = ref(0)//用户gm币
	const remainGMCoin = ref(0)
	const payAllGMcoin = ref(0)//选中gm币
	const payAllCoupon = ref(0)//选中抵扣券金额
	const payAllCouponId = ref(0)//选中抵扣券Id
	const payCouponType = ref(0)//选中抵扣券类型
	const inputAllGMcoin = ref(null)//输入gm币
	const inputMaxGMcoin = ref(0)//输入限制最大gm币
	const isMultiple = ref(false)//是否叠加使用
	const showCouponList = ref(false)
	const showGMCoin = ref(false)
	const payLoading = ref(false)
	const isShowPayType = ref(false)
	const showPayTypeSelect = ref(false)
	// const orderInfo = ref({})
	const payUrl = ref('')
	const payTypeSelectList = ref([
		{text:'기본 결제',value:'default'}
	])
	const nowPayTypeText = ref('기본 결제')
	const nowPayTypeValue = ref('default')
	const couponList = ref([])
	
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
	const userSign = computed(() => {
		return useUserStore().userSign
	})
	const roleInfo = computed(() => {
		return useUserStore().roleInfo
	})
	const orderInfo = computed(() => {
		return useUserStore().orderInfo
	})
	const formatCouponTypeStr = (row,str) => {
		let typeStr = '',moneyStr = '',descStr = '',discount = '';
		switch(row.type){
			case 0://全额
				typeStr = proxy.$t('pay.percentDiscount')
				moneyStr = row.num
				if(row.num > orderMoney.value){
					descStr = discount = orderMoney.value
				}else{
					descStr = discount = row.num
				}
				descStr = proxy.$t('pay.payMaxLimit',{ num: descStr })
				break;
			case 1://半价
				typeStr = proxy.$t('pay.halfPercentDiscount')
				moneyStr = row.num
				if(row.num > orderMoney.value / 2){
					descStr = discount = orderMoney.value / 2
				}else{
					descStr = discount = row.num
				}
				descStr = proxy.$t('pay.payMaxLimit',{ num: descStr })
				break;
			case 2://满减
				typeStr = proxy.$t('pay.fullReduction',{ num: row.doorsill })//'满'+row.doorsill+'减'+row.derate
				moneyStr = discount = row.derate
				break;
			default:
				break;
		}
		if(str == 'type'){
			return typeStr
		}else if(str == 'money'){
			return moneyStr
		}else if(str == 'desc'){
			return descStr
		}else if(str == 'discount'){
			return discount
		}
	}
	
	const getCoupons = () => {
		getUserCoupon({ money: orderMoney.value }).then((res) => {
			isMultiple.value = res.msg == '1' ? true : false
			// isMultiple.value = true//测试叠加使用方式
			let couponArr = res.data.sort((a,b) => {
				if(a.type == b.type){
					return b.derate - a.derate
				}else{
					return a.type - b.type
				}
			}) || []
			let effectArr = couponArr.filter((item) => item.effective == 1)
			let noeffectArr = couponArr.filter((item) => item.effective == 0)
			couponList.value = effectArr.concat(noeffectArr)
			getAutoDiscount()
			getGMcoins()
		})
	}
	const getGMcoins = () => {
		getUserInfo({}).then((res) => {
			allGMCoin.value = res.data.coin || 0
		})
	}
	// 取最优惠抵扣券金额
	const getAutoDiscount = () => {
		if(couponList.value.length == 0){
			return false
		}
		let newCoupons = couponList.value.map((item) => {
			item.discount = formatCouponTypeStr(item,'discount')
			if(item.effective == 1){
				return item
			}
		})
		let maxCoupon = newCoupons.sort((a,b) => b.discount - a.discount)[0]
		if(maxCoupon && maxCoupon.id){
			couponCheckd(maxCoupon)
		}
	}
	const couponCheckd = (row) => {
		couponList.value.forEach((item) => {
			if(item.id == row.id){
				item.checked = true
				payAllCoupon.value = -formatCouponTypeStr(item,'discount')
				payAllCouponId.value = item.id
				payCouponType.value = item.type
			}else{
				item.checked = false
			}
		})
	}
	const handleCouponCheck = (row) => {
		if(row.effective == 0){
			return false
		}
		couponCheckd(row)
		showCouponList.value = false
		handleCancelGM()
	}
	const handleCancelCoupon = () => {
		payAllCoupon.value = 0
		payAllCouponId.value = 0
		payCouponType.value = 0
		showCouponList.value = false
		couponList.value.forEach((item) => {
			item.checked = false
		})
	}
	const handleCancelGM = () => {
		payAllGMcoin.value = 0
		remainGMCoin.value = 0
		inputAllGMcoin.value = null
		showGMCoin.value = false
	}
	const handleConfirmGM = () => {
		if(!isMultiple.value){//不能叠加
			if(inputAllGMcoin.value%100 != 0){
				proxy.$toast(proxy.$t('pay.gmCoinPlaceholder'))
				return false
			}
			if(inputAllGMcoin.value > 0){
				handleCancelCoupon()
			}
		}else{
			let needPayMoney = parseInt(orderMoney.value) + parseInt(payAllCoupon.value) - inputAllGMcoin.value
			if(needPayMoney%100 != 0){
				proxy.$toast(proxy.$t('pay.needPayTips'))
				return false
			}
		}
		payAllGMcoin.value = -inputAllGMcoin.value
		showGMCoin.value = false
	}
	const onConfirm = ({ selectedOptions }) =>{
		nowPayTypeText.value = selectedOptions[0].text
		nowPayTypeValue.value = selectedOptions[0].value
		showPayTypeSelect.value = false
		console.log(nowPayTypeValue.value);
	}
	const goSuperMall = () => {
		handleCancelGM()
		let params = JSON.stringify({ type: 2})//道具商城子栏目type:2跳gm币签到页，栏目9奇葩商城页
		proxy.commonPostMsg({ action: 'gotoGM', data: 9, params: proxy.$Base64.encode(params)})
	}
	const handleOpenGMcoin = () => {
		if(isMultiple.value){//能叠加
			inputMaxGMcoin.value = parseInt(orderMoney.value) + parseInt(payAllCoupon.value)
		}else{
			inputMaxGMcoin.value = parseInt(orderMoney.value)
		}
		if(inputMaxGMcoin.value > 100){
			showGMCoin.value = true
		}else{
			proxy.$toast(proxy.$t('pay.payLimitTips'))
		}
	}
	const handlePayTypeSelect = () => {
		console.log(roleInfo.value.gameId);
		payTypeSelectList.value = [
			{text:'기본 결제',value:'default'}
		]
		getHgPayTypeInfo().then(res=>{
			console.log(res);
			let list = Object.entries(res.data).map(([key, value]) => {
			    return {
			        text: value,
			        value: key
			    };
			});
			payTypeSelectList.value = payTypeSelectList.value.concat(list)
			showPayTypeSelect.value = true
		})
	}
	const handleInputGMcoin = (val) => {
		let maxInput = Math.min(allGMCoin.value,inputMaxGMcoin.value)
		if(val > maxInput){
			inputAllGMcoin.value = maxInput
			remainGMCoin.value = 0
		}else{
			remainGMCoin.value = allGMCoin.value - val
		}
	}
	const handlePay = () => {
		console.log('支付')
		if(payLoading.value){
			proxy.$toast('Loading...')
			return false
		}
		payLoading.value = true
		let args = {
			orderId: orderInfo.value.orderId,
			totalAmount: orderInfo.value.totalMoney,
			userId: orderInfo.value.SuperUserId,
			goodId: orderInfo.value.goodid,
			gameId: roleInfo.value.gameId,
			goodName: orderInfo.value.goodName,
			num: orderInfo.value.num,
			voucher: 0,
			voucherType: 0
		}
		// 是否选抵扣券
		if(payAllCouponId.value){
			args.voucherId = payAllCouponId.value
			args.voucher = Math.abs(payAllCoupon.value)
			args.voucherType = payCouponType.value
		}
		// 是否使用GM币
		if(payAllGMcoin.value){
			args.platCoin = Math.abs(payAllGMcoin.value)
		}
		args.totalAmount = parseInt(orderMoney.value) + parseInt(payAllCoupon.value) + parseInt(payAllGMcoin.value)
		console.log(args)
		if(nowPayTypeValue.value == 'default'){
			orderPay(args).then((res) => {
				console.log(res)
				if(res.data && res.data.redirectUrl){
					payUrl.value = res.data.redirectUrl
					proxy.commonPostMsg({ action: 'webPayAction', data: payUrl.value })
				}else{
					proxy.sdkPay('pay_success',res.msg)
					proxy.$toast(res.msg)
				}
				payLoading.value = false
			}).catch((err) => {
				proxy.sdkPay('pay_fail',err.msg)
				payLoading.value = false
			})
		}else{
			args.wayCode = nowPayTypeValue.value
			orderEnjoy(args).then(res=>{
				if(res.data && res.data.payData){
					proxy.commonPostMsg({ action: 'webPayAction', data:res.data.payData })
				}else{
					proxy.sdkPay('pay_success',res.msg)
					proxy.$toast(res.msg)
				}
				payLoading.value = false
			}).catch((err) => {
				proxy.sdkPay('pay_fail',err.msg)
				payLoading.value = false
			})
		}
		
	}
	const showPayType = () =>{
		isShowPayType.value = true
	}
	const handlePayTypeCancel = () =>{
		isShowPayType.value = false
	}
	const handlePayCancel = () => {
		if(payLoading.value){
			proxy.$toast('Loading...')
			return false
		}
		payAllGMcoin.value = 0
		payAllCoupon.value = 0
		payUrl.value = ''
		proxy.sdkPay('pay_fail','')
	}
	watch(userSign, (newVal, oldVal) => {
		if(!newVal){
			proxy.commonPostMsg({ action: 'webDidLoad' })
		}else{
			proxy.commonPostMsg({ action: 'webPayMsgInfo' })
		}
	},{ immediate: true })
	watch(orderInfo, (newVal, oldVal) => {
		if(newVal){
			orderMoney.value = newVal.totalMoney
			getCoupons()
		}
	},{ immediate: true })
	watch(allGMCoin, (newVal, oldVal) => {
		if(isMultiple.value && newVal > 100){
			inputMaxGMcoin.value = parseInt(orderMoney.value) + parseInt(payAllCoupon.value)
			inputAllGMcoin.value = Math.min(allGMCoin.value,inputMaxGMcoin.value)
			payAllGMcoin.value = -inputAllGMcoin.value
		}
	})
</script>

<style lang="less" scoped>
	.paymentBox{
		padding: 0.9375rem 0 0;height: 100vh;box-sizing: border-box;
		/deep/ .van-cell{
			font-size: 1.125rem;
		}
		/deep/ .van-field__label{
			font-weight: 500;
		}
		.btnBoxs{
			display: flex;align-items: center;justify-content: space-between;
			.btnBox{
				display: inline-block;text-align: center;
				font-size: 1.125rem;
				padding: 0.5rem 1.875rem;border-radius: 0.3125rem;width: 42%;
				border: 0.0625rem solid #51BAC0;color: #51BAC0;box-sizing: border-box;
				&.disabled{
					pointer-events: none;cursor: default;
				}
				&.active{
					border: none;
					background: linear-gradient(to right,#51BAC0,#85D6DA);color: #fff;
				}
			}
			
		}
		.payTypeTip{
			font-size: 0.825rem;position: absolute;bottom: 0;right: 0;
			color: #969799;display: inline-block;z-index: 111;
		}
		.payTypeBox{
			max-width:unset;min-width:unset;
			padding: 0 1rem !important;
		}
		.payBox{
			width: 100%;padding: 0;box-sizing: border-box;height: 100%;position: relative;
			margin: 0 auto;
			p{ margin: 0.625rem 0; }
			.titleTxt{
				margin: 0 0 0.625rem;font-size: 1.25rem;font-weight: 600;
				display: flex;align-items: center;justify-content: center;
			}
			.discountTips{
				margin: 0.3125rem var(--van-cell-horizontal-padding);
				display: inline-block;background: #f8d6d5;border-radius: 0.375rem;padding: 0.125rem 0.5rem;
				color: #e92d2c;font-size: 0.75rem;
			}
			.discountUseTips{
				margin: var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding);
				font-size: 0.75rem;text-align: right;color: #ccc;
			}
			.payForm{
				.discount{
					/deep/ input{
						color: #ee0a24;
					}
				}
			}
			.payResult{
				padding: 0 var(--van-cell-horizontal-padding) 1rem;position: absolute;bottom: 0;left: 0;width: 100%;box-sizing: border-box;
				background: #fff;
				.moneyBox{
					font-size: 1.125rem;font-weight: 400;margin-bottom: 0.75rem;position: relative;
					p{
						margin: 0.5rem 0 0; 
						&:first-child{
							height: 1.5625rem;
						}
						&:nth-child(2){
							font-size: 0.8125rem;
						}
					}
					.total{
						b{
							font-size: 1.5rem;
						}
					}
					.discount{
						color: #51BAC0;
					}
				}
				
				
			}
		}
		&.pcbox{
			
		}
		.couponBox{
			width: 100%;
			.couponTitle{
				width: 100%;padding: 0.875rem 0;text-align: center;font-size: 1.375rem;font-weight: 600;
			}
			.couponList,.gmForm{
				width: 100%;max-height: 25rem;overflow-y: scroll;margin: 0;
				padding-top: 0.3125rem;padding-bottom: 3.75rem;
			}
			.couponList{
				/deep/.van-coupon{
					background: url('~@/assets/images/common/coupon_bg.png') no-repeat 100%;opacity: 0.35;
					&.active{
						opacity: 1;
					}
					&.disabled{
						filter: grayscale(100%);
					}
				}
				/deep/.van-coupon__name{
					font-size: 1.125rem;line-height: 1.5rem;
				}
				/deep/.van-coupon__valid{
					color: #E82D2D;
				}
				.redNum{
					display: inline-block;float: right;color: #E82D2D;
					&.big{
						font-size: 1.75rem;font-weight: 800;
					}
				}
			}
			.gmForm{
				margin-bottom: 1.875rem;
			}
			.btnItem{
				width: 50%;float: left;padding: 0 0.625rem;box-sizing: border-box;
			}
		}
	}
	
</style>