<template>
	<div class="typeShow" >
		<h1>한국</h1>
		<h2 style="margin-top: 1rem;">APPLEPAY</h2>
		<table style="white-space:nowrap;">
			<tbody>
				<tr>
					<td>결제 방식 유형</td>
					<td>목표 기관</td>
					<td>최소 금액</td>
					<td>최대 금액</td>
					<td>주문 종료 시간</td>
					<td>환불 지원 여부</td>
					<td>부분 환불 지원 여부</td>
					<td>아이콘</td>
					<td>설명</td>
				</tr>
			</tbody>
			<tbody>
				<tr>
					<td>APPLEPAY</td>
					<td>/</td>
					<td>KRW 1</td>
					<td>/</td>
					<td>30m</td>
					<td>Y</td>
					<td>Y</td>
					<td>
						<img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/Apple_Pay_Global.png" class="medium-zoom-image">
					</td>
					<td>/</td>
				</tr>
			</tbody>
		</table>
		<h2 style="margin-top: 1rem;">GOOGLEPAY</h2>
		<table style="white-space:nowrap;">
			<tbody>
				<tr>
					<td>결제 방식 유형</td>
					<td>목표 기관</td>
					<td>최소 금액</td>
					<td>최대 금액</td>
					<td>주문 종료 시간</td>
					<td>환불 지원 여부</td>
					<td>부분 환불 지원 여부</td>
					<td>아이콘</td>
					<td>설명</td>
				</tr>
			</tbody>
			<tbody>
				<tr>
					<td>GOOGLEPAY</td>
					<td>/</td>
					<td>KRW 1</td>
					<td>/</td>
					<td>30m</td>
					<td>Y</td>
					<td>Y</td>
					<td>
						<img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/Google_Pay_Global.png" class="medium-zoom-image">
					</td>
					<td>/</td>
				</tr>
			</tbody>
		</table>
		<h2 style="margin-top: 1rem;">APM</h2>
		<table style="white-space:nowrap;">
			<tbody>
				<tr>
					<td>결제 방식 유형</td>
					<td>목표 기관</td>
					<td>최소 금액</td>
					<td>최대 금액</td>
					<td>주문 종료 시간</td>
					<td>환불 지원 여부</td>
					<td>부분 환불 지원 여부</td>
					<td>아이콘</td>
					<td>설명</td>
				</tr>
			</tbody>
			<tbody>
				<tr>
					<td rowspan="6">WALLET</td>
					<td>KAKAOPAY</td>
					<td>KRW 50</td>
					<td>/</td>
					<td>30m</td>
					<td>Y</td>
					<td>Y</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/KAKAOPAY.png" class="medium-zoom-image"></td>
					<td>50-100만원은 특수 신청을 해야합니다</td>
				</tr>
				<tr>
					<td>TOSS</td>
					<td>KRW 1</td>
					<td>KRW 2000000</td>
					<td>30m</td>
					<td>Y</td>
					<td>Y</td>
					<td><img style="border:none;" src="https://img-cdn.shareitpay.in/payWayImg/Toss_KR.png" class="medium-zoom-image"></td>
					<td></td>
				</tr>
				<tr>
					<td>PAYCO</td>
					<td>KRW 1</td>
					<td>KRW 1000000</td>
					<td>1h</td>
					<td>Y</td>
					<td>Y</td>
					<td><img style="border:none;" src="https://img-cdn.shareitpay.in/payWayImg/PAYCO_KR.png" class="medium-zoom-image"></td>
					<td></td>
				</tr>
				<tr>
					<td>SSGPAY</td>
					<td>KRW 100</td>
					<td>/</td>
					<td>1h</td>
					<td>Y</td>
					<td>Y</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/SSGPAY_KR.png" class="medium-zoom-image"></td>
					<td></td>
				</tr>
				<tr>
					<td> NAVERPAY </td>
					<td>KRW 100</td>
					<td>/</td>
					<td>1h</td>
					<td>Y</td>
					<td>Y</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/Never_Pay_KR.png" class="medium-zoom-image"></td>
					<td></td>
				</tr>
				<tr>
					<td>SAMSUNGPAY</td>
					<td>KRW 100</td>
					<td>/</td>
					<td>1h</td>
					<td>Y</td>
					<td>Y</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/Samsung_Pay_KR.png" class="medium-zoom-image"></td>
					<td></td>
				</tr>
				<tr>
					<td rowspan="2">PRE_PAID</td>
					<td>TMONEY</td>
					<td>KRW 10</td>
					<td>KRW 500000</td>
					<td>1h</td>
					<td>Y</td>
					<td>Y</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/T-money_KR.png" class="medium-zoom-image"></td>
					<td>환불 유효기간：D+24</td>
				</tr>
				<tr>
					<td>EGGMONEY</td>
					<td>KRW 10</td>
					<td>/</td>
					<td>1h</td>
					<td>Y</td>
					<td>N</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/Eggmoney_KR.png" class="medium-zoom-image"></td>
					<td>환불 유효기간 : D+24; 일일 결제 제한 : 동일 가입자 아이디 또는 동일 IP 주소로 3초 이내에 2차 결제 완료 불가<br>동일한 사용자 ID 및 동일한 IP주소로 20건만 결제 가능합니다.<br>동일한 PIN number는 3초 이내에 두 번째 성공적인 결제를 완료할 수 없습니다.<br>동일 PIN number(gift certificate 100,000원 미만)의 경우 10건까지만 결제 가능합니다.<br>동일 PIN number(KRW 100,000 gift certificate)의 경우 40건까지만 결제 가능합니다.</td>
				</tr>
				<tr>
					<td>NET_BANKING</td>
					<td>NETBANKING</td>
					<td>KRW 150</td>
					<td>KRW 2000000</td>
					<td>1h</td>
					<td>Y</td>
					<td>Y</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/C0_Bank_Transfer.png" class="medium-zoom-image"></td><td></td>
				</tr>
				<tr>
					<td>BANK_TRANSFER</td>
					<td>BANKTRANSFER</td>
					<td>KRW 100</td>
					<td>/</td>
					<td>7D</td>
					<td>N</td>
					<td>N</td>
					<td><img style="border:none;" src="https://img-cdn.shareitpay.in/payWayImg/Z0_Virtual_Account.png" class="medium-zoom-image"></td><td>현지 소비 습관에 따라 사용자 측에 표시되는 이름은 Virtual Account</td>
				</tr>
			</tbody>
			<tbody>
				<tr>
					<td rowspan="19">REDIRECT_CARD</td>
					<td>BC_CARD</td>
					<td rowspan="19">KRW 100</td>
					<td rowspan="19">/</td>
					<td rowspan="19">1h</td>
					<td rowspan="19">Y</td>
					<td rowspan="19">Y</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/BC_KR.png" class="medium-zoom-image"></td>
					<td rowspan="19">국내 신용카드 지원, 1등급 명칭     신용 카드</td>
				</tr>
				<tr>
					<td>KOOKMIN_CARD</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/Kookmin_KR.png" class="medium-zoom-image"></td>
				</tr>
				<tr>
					<td>HANA_CARD</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/Hana_card_KR.png" class="medium-zoom-image"></td>
				</tr>
				<tr>
					<td>SAMSUNG_CARD</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/Samsung_KR.png" class="medium-zoom-image"></td>
				</tr>
				<tr>
					<td>SHINHAN_CARD</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/ShinhanBank_KR.png" class="medium-zoom-image"></td>
				</tr>
				<tr>
					<td>HYUNDAI_CARD</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/HyundaiCard_KR.png" class="medium-zoom-image"></td>
				</tr>
				<tr>
					<td>LOTTE_CARD</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/LotteBank_KR.png" class="medium-zoom-image"></td>
				</tr>
				<tr>
					<td>NONGHYUP_CARD</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/NonghyupBank_KR.png" class="medium-zoom-image"></td>
				</tr>
				<tr>
					<td>CITI_CARD</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/CitiBank_KR.png" class="medium-zoom-image"></td>
				</tr>
				<tr>
					<td>SUHYUP_CARD</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/SuhyupBank_KR.png" class="medium-zoom-image"></td>
				</tr>
				<tr>
					<td>WOORI_CARD</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/WooriBank_KR.png" class="medium-zoom-image"></td>
				</tr>
				<tr>
					<td>CREDIT_UNION</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/ShinhyupBank_KR.png" class="medium-zoom-image"></td>
				</tr>
				<tr>
					<td>KJ_CARD</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/KwangjuBank_KR.png" class="medium-zoom-image"></td>
				</tr>
				<tr>
					<td>JEONBUK_BANK</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/JeonbukBank_KR.png" class="medium-zoom-image"></td>
				</tr>
				<tr>
					<td>JEJU_BANK</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/JejuBank_KR.png" class="medium-zoom-image"></td>
				</tr>
				<tr>
					<td>KOREA_BANK</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/KoreaPost_KR.png" class="medium-zoom-image"></td>
				</tr>
				<tr>
					<td>MG_BANK</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/MGBank_KR.png" class="medium-zoom-image"></td>
				</tr>
				<tr>
					<td>SAVINGS_BANK</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/SavingsBank_KR.png" class="medium-zoom-image"></td>
				</tr>
				<tr>
					<td>KAKAO_BANK</td>
					<td><img style="border:none;" src="https://img-cdn.payermax.com/payWayImg/kakaoBank_KR.png" class="medium-zoom-image"></td>
				</tr>
			</tbody>
		</table>
		<h2 style="margin-top: 1rem;">카드결제방법</h2>
		<table style="white-space:nowrap;">
			<tbody>
				<tr>
					<td>결제 방식 유형</td>
					<td>목표 기관</td>
					<td>최소 금액</td>
					<td>최대 금액</td>
					<td>주문 종료 시간</td>
					<td>환불 지원 여부</td>
					<td>부분 환불 지원 여부</td>
					<td>아이콘</td>
					<td>설명</td>
				</tr>
			</tbody>
			<tbody>
				<tr>
					<td>CARD</td>
					<td>/</td>
					<td>KRW 1</td>
					<td>/</td>
					<td>1h</td>
					<td>Y</td>
					<td>Y</td>
					<td>
						<img style="border:none;display: block;width:auto;" src="https://img-cdn.shareitpay.in/img_Card_Associations/visa_C_A.png" class="medium-zoom-image">
						<img style="border:none;display: block;width:auto;" src="https://img-cdn.shareitpay.in/img_Card_Associations/mastercard_C_A.png" class="medium-zoom-image">
					</td>
					<td>국제 카드 단체 Visa 및 MasterCard 지원 (별도 신청 필요)</td>
				</tr>
			</tbody>
		</table>
		<h2 style="margin-top: 1rem;">결제 은행</h2>
		<table style="white-space:nowrap;">
			<tbody>
				<tr>
					<td>결제 방식</td>
					<td>결제 은행</td>
					<td>결제 방식</td>
					<td>결제 은행</td>
				</tr>
				<tr>
					<td rowspan="22">NET_BANKING</td>
					<td>KDB Bank</td>
					<td rowspan="22">BANK_TRANSFER (virtual account) </td>
					<td>IBK</td>
				</tr>
				<tr>
					<td>IBK</td>
					<td>KB</td>
				</tr>
				<tr>
					<td>KB Kookmin</td>
					<td>NH</td>
				</tr>
				<tr>
					<td> Hana</td>
					<td>Woori</td>
				</tr>
				<tr>
					<td>Suhyup</td>
					<td>Shinhan</td>
				</tr>
				<tr>
					<td>NH</td>
					<td>Sh</td>
				</tr>
				<tr>
					<td>Woori</td>
					<td>Gyeongnam</td>
				</tr>
				<tr>
					<td>Shinhan</td>
					<td>Hana</td>
				</tr>
				<tr>
					<td>SC</td>
					<td>KJB</td>
				</tr>
				<tr>
					<td>Citi</td>
					<td>Busan</td>
				</tr>
				<tr>
					<td>DGB</td>
					<td>epost</td>
				</tr>
				<tr>
					<td>Busan</td>
					<td>SC</td>
				</tr>
				<tr>
					<td>KJB</td>
					<td>DGB</td>
				</tr>
				<tr>
					<td>Jeju</td>
					<td></td>
				</tr>
				<tr>
					<td>Chunbuk</td>
					<td></td>
				</tr>
				<tr>
					<td>Gyeongnam</td>
					<td></td>
				</tr>
				<tr>
					<td>MG</td>
					<td></td>
				</tr>
				<tr>
					<td>Shinhyup</td>
					<td></td>
				</tr>
				<tr>
					<td>Korea Forest Cooperative Bank</td>
					<td></td>
				</tr>
				<tr>
					<td>epostbank</td>
					<td></td>
				</tr>
				<tr>
					<td> K-bank</td>
					<td></td>
				</tr>
				<tr>
					<td>Kakao Bank</td>
					<td></td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
</script>

<style  lang="less" scoped>
	// ::-webkit-scrollbar {
	//   display: unset !important;
	// }
	.typeShow{
		padding: 0.5rem 1rem;
		// height: 100%;
		position: relative;background-color: #000;color: rgba(255, 255, 245, 0.86);
		
		table{
			display: block;
			border-collapse: collapse;
			margin: 20px 0;
			overflow-x: auto;
			overflow-y: auto;
			width: 100%;max-height: 18.75rem;
			// tbody{
			// 	overflow-x: auto;
			// }
			
			tr{
				background-color: #1b1b1f;
				border-top: 1px solid #2e2e32;
				transition: background-color .5s;
				
				td{
					font-size: 14px;
					border: 1px solid #2e2e32;
					padding: 8px 16px;
				}
				img {
				    width: 28px;
				    height: 28px;
				}
			}
		}
	}
</style>