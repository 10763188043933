import request from '@/utils/request'
//支付
export function orderPay(data){
	return request({
	  url: '/pay/orderPay',
	  method: 'post',
	  data:data
	})
}
//enjoy支付
export function orderEnjoy(data){
	return request({
	  url: '/enjoy/pay/payOrder',
	  method: 'post',
	  data:data
	})
}
//代金券
export function getUserCoupon(data){
	return request({
	  url: '/voucherActivityUser/getInfo',
	  headers: {
	    isToken: true
	  },
	  method: 'post',
	  data:data
	})
}
export function getUserInfo(data){
	return request({
	  url: '/propMall/getUserInfo',
	  headers: {
	    isToken: true
	  },
	  method: 'post',
	  data:data
	})
}
// 支付方式
export function getHgPayTypeInfo(){
	return request({
	  url: '/enjoy/pay/getHgPayTypeInfo',
	  method: 'get',
	})
}